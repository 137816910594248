import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const CRadio = styled(Radio)`
  &.MuiRadio-root {
    color: ${(props) =>
      props.selected ? props.theme.colors.primary : props.theme.colors.default};
  }
`;

const CLabel = styled(FormControlLabel)`
  & .MuiTypography-body1 {
    font-size: 1.125rem;
  }
`;

function Radios({ options: list, defaultValue = '', onChange, className }) {
  const [value, setValue] = useState(defaultValue);
  const [options, setOptions] = useState(list);

  const handleChange = (e) => {
    const v = e.target.value;
    setValue(v);
    onChange(v);
  };

  useEffect(() => {
    setOptions(list);
    setValue(defaultValue);
  }, [defaultValue, list]);

  return (
    <FormControl className={className} component="fieldset">
      <RadioGroup
        row
        aria-label="idtype"
        name="idtype"
        value={value}
        onChange={handleChange}
      >
        {options.map((option) => {
          const v = option?.value;
          const label = option?.label || option?.name;
          return (
            <CLabel
              key={label}
              value={v}
              control={<CRadio selected={v === value} />}
              label={label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

Radios.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string || PropTypes.number,
    })
  ),
};

export default Radios;
